import { Controller } from "@hotwired/stimulus";

import Swiper from "swiper/bundle";

export default class extends Controller {
  connect() {
    this.preview_swiper = new Swiper(".preview-swiper", {
      spaceBetween: 10,
      slidesPerView: "auto",
      freeMode: true,
      watchSlidesProgress: true,
    });

    this.main_swiper = new Swiper(".main-swiper", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: this.preview_swiper,
      },
    });
  }
}
